import React from "react";
import { Box } from "@mui/material";
import { Icon } from "@iconify/react";
import { Link, useLocation } from "react-router-dom";
import { en } from "../Helpers/en";
import { getLocalData, getLocalString } from "../Helpers";

const MasterMenu = [
  {
    icon: "la:car-side",
    title: "Purpose Of Visit",
    url: "/visitor-purpose",
  },
  {
    icon: "mdi:people-outline",
    title: "Visitor's Type",
    url: "/visitor-type",
  },
  {
    icon: "mi:location",
    title: "Location",
    url: "/location",
  },
  {
    icon: "pepicons-pencil:people",
    title: "Client",
    url: "/client",
  },
  {
    icon: "bi:building",
    title: "Building",
    url: "/building",
  },
  {
    icon: "solar:key-broken",
    title: "Tenant",
    url: "/tenant",
  },
  {
    icon: "carbon:gateway-public",
    title: "Gate",
    url: "/gate",
  },
  {
    icon: "eos-icons:system-ok-outlined",
    title: "System",
    url: "/system",
  },
  {
    icon: "clarity:host-line",
    title: "Host",
    url: "/host",
  },
  {
    icon: "fluent:approvals-app-28-regular",
    title: "Approval",
    url: "/approval",
  },
];
const VehicleMenu = [
  {
    title: "Register",
    icon: "mdi:register-outline",
    url: "/vehicle-register",
  },
  {
    title: "Live Report",
    icon: "ph:table",
    url: "/vehicle-live-report",
  },
  {
    title: "Consolidated Report",
    icon: "mdi:car-outline",
    url: "/vehicle-consolidated-report",
  },
  {
    title: "Skip Report",
    icon: "carbon:skip-forward",
    url: "/vehicle-skip-report",
  },
  {
    title: "Mismatch Report",
    icon: "material-symbols:report-outline",
    url: "/mismatch-report",
  },
];
const WalkinMenu = [
  {
    title: "Register",
    icon: "mdi:register-outline",
    url: "/walkin-resgister",
  },
  {
    title: "Live Report",
    icon: "tabler:walk",
    url: "/walkin-report",
  },
  {
    title: "Consolidated Report",
    icon: "tabler:walk",
    url: "/walkin-consolidated-report",
  },
];

const Navigation = () => {
  const location = useLocation();
  const { pathname } = location;
  const loginType = JSON.parse(JSON.stringify(getLocalString("logInType")));
  const loginData = getLocalData("loginData");
  const userType =
    loginData !== null && loginData !== undefined ? loginData?.userType : 1;
  return (
    <>
      <Box
        sx={{
          padding: "10px 0px",
          "& a": {
            display: "flex",
            alignItems: "center",
            padding: "8px 10px ",
            textDecoration: "inherit",
            fontSize: 16,
            color: "#fff",
            position: "relative",
            fontWeight: 400,
            "& svg": {
              marginRight: 2,
              fontSize: 25,
              opacity: 0.5,
            },
          },
          "& a.active": {
            backgroundColor: "primary.main",
            color: "#fff",
          },
          ".heading": {
            color: "#fff",
            fontWeight: "400",
            padding: "0px 10px 20px",
            opacity: 0.5,
          },
        }}
      >
        <Link
          to={"/dashboard"}
          key={1}
          className={pathname === "/dashboard" ? "active" : ""}
        >
          <Icon icon={"material-symbols:dashboard"} /> {en?.Dashboard}
        </Link>
        <Box sx={{ height: 25 }}></Box>

        {userType !== 2 && userType !== 3 && (
          <>
            <p className="heading">{en?.WalkIn}</p>
            {WalkinMenu?.map((item, index) => (
              <Link
                to={item.url}
                key={index}
                className={pathname === item.url ? "active" : ""}
              >
                <Icon icon={item?.icon} />
                {item.title}
              </Link>
            ))}
            <Box sx={{ height: 25 }}></Box>
          </>
        )}
        {userType !== 1 && userType !== 3 && (
          <>
            <p className="heading">{en?.Vehicle}</p>
            {VehicleMenu?.map((item, index) => (
              <Link
                autoFocus
                to={item.url}
                key={index}
                className={pathname === item.url ? "active" : ""}
              >
                <Icon icon={item?.icon} />
                {item.title}
              </Link>
            ))}
            <Box sx={{ height: 25 }}></Box>
          </>
        )}

        {loginType === en?.Admin && (
          <Box>
            <p className="heading">{en?.Master}</p>

            {MasterMenu?.map((item, index) => (
              <Link
                id={item?.url}
                to={item.url}
                key={index}
                className={pathname === item.url ? "active" : ""}
              >
                <Icon icon={item?.icon} />
                {item.title}
              </Link>
            ))}
          </Box>
        )}
      </Box>
    </>
  );
};

export default Navigation;
