import React, { useState } from "react";
import { Box, useTheme } from "@mui/material";
import Header from "../header";
import Sidebar from "../sidebar";
import MobileHeader from "../mobileHeader";

const Dashboard = ({ title, button,  children }) => {
  const theme = useTheme();
  const [menu, setMenu] = useState(false);

  const handleMenuClick = () => {
    setMenu(!menu);
  };

  return (
    <>
      <Sidebar menuShow={menu} />
      <MobileHeader toggleMenu={handleMenuClick} toggleState={menu} />
      <Box
        sx={{
          minHeight: "100vh",
          background: "#ffffff",
          paddingLeft: "280px",
          paddingTop: "30px",
          paddingRight: "30px",
          paddingBottom: "30px",
          [theme.breakpoints.down("sm")]: {
          padding: "15px",
          paddingTop: "55px",
          background: "#fff",
          overflowX: "hidden"
          },
        }}
      >
      <Header title={title} button={button} />
        {children}
      </Box>
    </>
  );
};

export default Dashboard;
