import React from "react";
import { Box } from "@mui/material";
import { Link as RouterLink } from "react-router-dom";

const Logo = () => {
  return (
    <Box
      className="logo"
      sx={{
        a: {
          textDecoration: "none",
          fontSize: 30,
          fontWeight: 700,
          color: "primary.main",
        },
      }}
    >
      <RouterLink to="/dashboard">VMS</RouterLink>
    </Box>
  );
};

export default Logo;
