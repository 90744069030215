import React from "react";
import { Box } from "@mui/material";
import CardChildren from "./card/cardChildren";

import { DataGrid } from "@mui/x-data-grid";

const MainTable = (props) => {
  const { rows, columns, getRowClassName, onCellEditStop, children } = props;
  return (
    <CardChildren>
      <Box sx={{ width: "100%", marginBottom: "20px" }}>{children}</Box>
      <Box>
        <div style={{ height: 440, width: "100%" }}>
          <DataGrid
            {...props}
            getRowId={(row) => row?._id}
            rows={rows}
            rowHeight={45}
            columnHeaderHeight={45}
            onCellEditStop={(params, event) => {
              if (onCellEditStop !== undefined && event.target !== undefined) {
                onCellEditStop(params, event.target.value);
              }
            }}
            columns={columns}
            disableColumnSelector={true}
            getRowHeight={() => "45"}
            initialState={{
              pagination: {
                paginationModel: { page: 0, pageSize: 25 },
              },
            }}
            pageSizeOptions={[25, 50, 75, 100]}
            getRowClassName={() => getRowClassName}
            sx={{
              color: "#666",

              "& .MuiDataGrid-columnHeaders": {
                background: "#f1f1f1",
                borderRadius: 0,
                color: "#666",
              },
              "& .MuiDataGrid-cell, & .MuiDataGrid-columnHeader": {
                outline: "inherit!important",
              },

              "& .MuiDataGrid-footerContainer": {},
              "& .MuiIconButton-root svg": {
                fontSize: 23,
              },
              "& .MuiDataGrid-row:hover": {},
              "& .red": {
                background: "#ffd5d5",
              },
              "& .green": {
                background: "#d5ffda",
              },
              "& .grey": {
                background: "#e7e7e7",
              },
              "& .MuiDataGrid-virtualScroller::-webkit-scrollbar": {
                width: "0.4em",
                height: "0.6em",
              },
              "& .MuiDataGrid-virtualScroller::-webkit-scrollbar-track": {
                background: "#f1f1f1",
              },
              "& .MuiDataGrid-virtualScroller::-webkit-scrollbar-thumb": {
                backgroundColor: "#888",
              },
              "& .MuiDataGrid-virtualScroller::-webkit-scrollbar-thumb:hover": {
                background: "#555",
              },

              "& .MuiTablePagination-root": {
                color: "#666",
              },
            }}
          />
        </div>
      </Box>
    </CardChildren>
  );
};

export default MainTable;
