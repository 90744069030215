import React from "react";
import { Box } from "@mui/material";

const CardChildren = ({ children }) => {
  return (
    <>
      {/*
    <Box
      sx={{
        background: "#fff",
        p: "20px",
        display: "inline-block",
        width: "100%",
        borderRadius: 1,
        boxShadow: "0px 0px 20px 0px rgb(0 0 0 / 12%)",
        [theme.breakpoints.down("sm")]: {
          padding: "0px",
          boxShadow: "inherit",
          borderRadius: 0,
          overflowX: "hidden"
        },
      }}
    >
    */}
      <Box>{children}</Box>
    </>
  );
};

export default CardChildren;
