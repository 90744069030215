import React, { useState, useRef } from "react";
import {
  Box,
  Avatar,
  Button,
  Menu,
  Divider,
  Badge,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Typography,
} from "@mui/material";
import { Icon } from "@iconify/react";

const NotificationDropdown = () => {
  const anchorRef = useRef(null);
  const [open, setOpen] = useState(false);

  const handleClick = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Box sx={{ position: "relative" }}>
      <Button
        sx={{
          padding: 0,
          minWidth: "inherit",
          color: "inherit",
          width: 45,
          height: 45,
          fontSize: 35,
          "&:hover": {
            background: "none",
          },
        }}
        disableFocusRipple
        disableElevation
        disableRipple
        onClick={handleClick}
        ref={anchorRef}
      >
        <Badge badgeContent={4} color="primary">
          <Icon icon="mdi:bell-outline" />
        </Badge>
      </Button>

      <Menu
        id="account-menu"
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        anchorEl={anchorRef.current}
        sx={{
          "& .MuiPaper-root": {
            width: 360,
            mt: 1.1,
          },
        }}
      >
        <Box sx={{ my: 1.5, px: 2.5 }}>
          <Typography
            variant="subtitle1"
            noWrap
            sx={{ fontWeight: 600, fontSize: 20 }}
          >
            Notifications
          </Typography>
        </Box>

        <Divider sx={{ my: 1 }} />

        <List
          sx={{ width: "100%", maxWidth: 360, bgcolor: "background.paper" }}
        >
          <ListItem alignItems="flex-start">
            <ListItemAvatar>
              <Avatar alt="Remy Sharp" src="/images/avatar.jpg" />
            </ListItemAvatar>
            <ListItemText
              primary="Brunch this weekend?"
              secondary={
                <React.Fragment>
                  <Typography
                    sx={{ display: "inline" }}
                    component="span"
                    variant="body2"
                    color="text.primary"
                  >
                    Ali Connors
                  </Typography>
                  {" — I'll be in your neighborhood doing errands this…"}
                </React.Fragment>
              }
            />
          </ListItem>
          <Divider variant="inset" component="li" />
          <ListItem alignItems="flex-start">
            <ListItemAvatar>
              <Avatar alt="Remy Sharp" src="/images/avatar.jpg" />
            </ListItemAvatar>
            <ListItemText
              primary="Summer BBQ"
              secondary={
                <React.Fragment>
                  <Typography
                    sx={{ display: "inline" }}
                    component="span"
                    variant="body2"
                    color="text.primary"
                  >
                    to Scott, Alex, Jennifer
                  </Typography>
                  {" — Wish I could come, but I'm out of town this…"}
                </React.Fragment>
              }
            />
          </ListItem>
          <Divider variant="inset" component="li" />
          <ListItem alignItems="flex-start">
            <ListItemAvatar>
              <Avatar alt="Remy Sharp" src="/images/avatar.jpg" />
            </ListItemAvatar>
            <ListItemText
              primary="Oui Oui"
              secondary={
                <React.Fragment>
                  <Typography
                    sx={{ display: "inline" }}
                    component="span"
                    variant="body2"
                    color="text.primary"
                  >
                    Sandra Adams
                  </Typography>
                  {" — Do you have Paris recommendations? Have you ever…"}
                </React.Fragment>
              }
            />
          </ListItem>
        </List>
      </Menu>
    </Box>
  );
};

export default NotificationDropdown;
