// material
import { GlobalStyles as GlobalThemeStyles } from '@mui/material';

// ----------------------------------------------------------------------

export default function GlobalStyles() {
  
  return (
    <GlobalThemeStyles
      styles={{
        '*': {
          margin: 0,
          padding: 0,
          boxSizing: 'border-box'
        },
        html: {
          width: '100%',
          height: '100%',
          WebkitOverflowScrolling: 'touch'
        },
        body: {
          width: '100%',
          fontFamily: "Public Sans, sans-serif",
          color: "#666",
        },
        img: {
          maxWidth: "100%",
          height: "auto"
        },
        '& body .MuiButtonBase-root:not(.MuiSwitch-switchBase)' : {
          minHeight: "40px"
        },
        '& body .MuiInputBase-input': {
          padding: '12px 14px',
          fontWeight: "700",
        },
        '& body .MuiOutlinedInput-notchedOutline': {
          borderColor: 'rgb(0 0 0 / 10%)',
        },

        '& body .MuiInputBase-root': {
          background: "#f5f5f5"
        },

        '& body .Toastify__toast': {
          fontFamily: "Public Sans, sans-serif",
          fontWeight: 600
        }

      }}
    />
  );
}
