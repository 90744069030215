import React from "react";
import { Box, Container } from "@mui/material";

const Boxed = ({ children }) => {
  return (
    <Box>
    <Container>
      {children}
    </Container>
    </Box>
  );
};

export default Boxed;
