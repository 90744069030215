export const en = {
  Dashboard: "Dashboard",
  ConsolidatedReport: "Vehicle Consolidated Report",
  Export: "Export",
  Search: "Search",
  Submit: "Submit",
  Clear: "Clear",
  ExportReport: "Export Report",
  FromDate: "From Date",
  ToDate: "To Date",
  UpdateVisitor: "Update Visitor",
  Name: "Name",
  ContactNo: "Contact No",
  Company: "Company",
  BDHostDept: "BD Host/Dept",
  VehicleNo: "Vehicle No",
  StickerNo: "Sticker No",
  Timein: "Time in",
  PurposeRemarks: "Purpose/Remarks",
  Back: "Back",
  VehicleNumber: "VehicleNumber",
  TagNo: "Tag No",
  CheckIn: "Check In",
  CheckOut: "Check Out",
  CreatedAt: "Created At",
  Purpose: "Purpose",
  LiveReport: "Live Report",
  EnterTagtoCheckout: "Enter Tag to Checkout",
  CheckOutButton: "CheckOut",
  VisitorDetails: "Visitor Details",
  NRIC: "NRIC",
  ContactNumber: "Contact Number",
  TagNumber: "Tag Number",
  Sticker: "Sticker",
  Host: "Host",
  NoHost: "No Host Data Found..!",
  NoLocation: "No Location Data Found..!",
  NoClient: "No Client Data Found..!",
  NoBuilding: "No Building Data Found..!",
  PurposeOfVisit: "Purpose Of Visit",
  NoPurpose: "No Purpose of Visit Data Found..!",
  NoGate: "No Gate Data Found..!",
  Update: "Update",
  Visitor: "Visitor",
  deletedsuccessfully: "deleted successfully",
  Date: "Date",
  isrequired: "is required",
  Contactnumber: "Contact number",
  TagError: "Tag number already exist",
  updatedsuccessfully: "updated successfully",
  completedsuccessfully: "completed successfully",
  Reset: "Reset",
  Create: "Create",
  createdsuccessfully: "created successfully",
  Edit: "Edit",
  Status: "Status",
  PurposeName: "Purpose Name",
  VisitorType: "Visitor Type",
  Approval: "Approval",
  Location: "Location",
  Client: "Client",
  Building: "Building",
  Clients: "Clients",
  Tenant: "Tenant",
  Gate: "Gate",
  System: "System",
  User: "User",
  Password: "Password",
  Designation: "Designation",
  LoginSuccess: "Admin Logged in successfully",
  SystemLogin: "System Logged in successfully",
  Admin: "Admin",
  Email: "Email",
  EmailUserName: "Email/UserName",
  isLoggedIn: "isLoggedIn",
  logInType: "logInType",
  loginData: "loginData",
  PageNotFound: "Page Not Found",
  PageNotFoundText:
    "Looks like you've followed a broken link or entered a URL that doesn't exist on this site.",
  BackToHome: "Back to Home",
  LogIn: " Log In",
  WelcomeBack: " Welcome Back",
  ForgotPassword: "Forgot Password",
  Login: "Login",
  DontHaveAccnt: "Don't you have an account?",
  CreateAccount: "Create Account",
  BackToLogin: "Back to Login",
  visitorRegisterationTitle: "Visitor / Registration",
  Master: "Master",
  vehicleRegisterationTitle: "Vehicle Registeration",
  BlockNo: "Block/Unit No",
  BlockLevel: "Block/Level",
  Remarks: "Remarks",
  VehicleLiveReport: "Vehicle Live Report",
  VehilceDetails: "Vehicle Details",
  UpdateVehicle: "Update Vehicle",
  VehicleImage: "Vehicle Image ",
  SkippedAt: "Skipped At",
  VehicleSkipReport: "Vehicle Skip Report",
  Register: "Register",
  Report: "Report",
  Vehicle: "Vehicle",
  WalkIn: "Walk-In",
  WalikinReport: "Walk-In Live Report",
  WalikinRegister: "Walk-In Registeration",
  MismatchReport: "Mismatch-Report",
  WalkinConsolidatedReport: "Walkin-Consolidated Report",
  UserType: "User Type",
  Bike: "Bike",
  adminLogin: "adminLogin",
  vehicleVisitors: "Visitors",
  vehicleLiveCount: "Live report",
  vehicleConsolidatedCount: "Consolidated report",
  vehicleSkipCount: "Skip report",
  vehicleMismatchCount: "Mismatch report",
  OverTimeVechicle: "Overtime Vechicle",
  NoOfClients: "Clients",
  NoOfLocations: "Locations",
  NoOfSystems: "Systems",
  walkinVisitors: "Visitors",
  bikeVisitors: "Bike visitors",
  walkinLive: "Live report",
  walkinConsolidated: "Consolidated report",
  withinTimeLimit: "Within Time Limit",
  timeLimitExceeded: "Time Limit Exceeded",
  currentDay: "Current Day",
  currentWeek: "Current Week",
  green: "green",
  red: "red",
  grey: "grey",
  TimeLimitExceeded: "Time Limit Exceeded",
  view: "view",
  edit: "edit",
  VmsClause: "VMS CLAUSE",
  VmsClauseDecline:
    "You had disagree, please contact the Security Counter for manual registration procedure.",
  Decline: "Decline",
  Agree: "Agree",
  generalPoint1: "The processing of the registration.",
  generalPoint2: "The administration of your registration.",
  generalPoint3:
    "For Visitors / Contractors : Granting access to limited premises.",
  generalPoint4:
    "For Courier / Delivery : Authentication and notifying recipient(s) the origin of the place / deliverables.",
  visitorPolicyPoint1:
    "Any person providing false information will be reported to the authorities.",
  visitorPolicyPoint2:
    "Abuse or Violence Against Security Officers is against the law.(By PSIA (Private Security Industry Act).)",
  VMSClause1:
    "By proceeding, you duly consent for Security to collect, use and disclose your personal data for the following purposes in accordance with the Personal Data Protection Act 2012 and our data protection policy : ",
  VMSClause2:
    "Data retention period will be 12 months upon registration as per limited policy.",
  VMSClause3:
    "You will not be able to proceed with registration if you do not agree.",
  VisitorPolicy: "Visitor Policy :",
  VehicleSkipped: "Vehicle Skipped",
  SelectTimeFormat: "Select Time Format",
  Hours: "Hours",
  Minutes: "Minutes",
  Timeisrequired: "Time is required",
};
