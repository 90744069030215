import React, { createContext } from "react";
import CssBaseline from "@mui/material/CssBaseline";
import { ThemeProvider } from "@mui/material/styles";
import theme from "./theme";
import GlobalStyles from "./theme/globalStyles";
// router
import { BrowserRouter } from "react-router-dom";
import { Router } from "./config";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import 'react-perfect-scrollbar/dist/css/styles.css';


export const AppContext = createContext();

function App() {
  const isLoggedIn = JSON.parse(localStorage.getItem("isLoggedIn"));
  return (
    <AppContext.Provider value={{ isLoggedIn }}>
      <ThemeProvider theme={theme}>
        <BrowserRouter>
          <CssBaseline />
          <GlobalStyles />
          <Router />
          <ToastContainer
            autoClose={2000}
            theme="colored"
            position={toast.POSITION.TOP_CENTER}
          />
        </BrowserRouter>
      </ThemeProvider>
    </AppContext.Provider>
  );
}

export default App;
