import React from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Button,
  useTheme,
} from "@mui/material";
import { Icon } from "@iconify/react";

const Modal = (props) => {
  const theme = useTheme();
  const { open, onClose, title, children, lg, xl } = props;
  return (
    <Dialog
      open={open}
      keepMounted
      onClose={onClose}
      disableEscapeKeyDown
      sx={{
        "& .MuiPaper-root": {
          width: "100%",
          maxWidth: lg ? (xl ? "1200px" : "800px") : "600px",
          padding: "20px",
          position: "relative",
          [theme.breakpoints.down("sm")]: {
            padding: "15px",
            margin: "10px",
          },
        },
      }}
    >
      <DialogTitle
        sx={{
          fontSize: 22,
          fontWeight: 600,
          marginBottom: 2,
          [theme.breakpoints.down("sm")]: {
            padding: 0,
          },
        }}
      >
        {title}

        <Button
          variant="text"
          block
          onClick={onClose}
          disableElevation
          sx={{
            padding: "0!important",
            minWidth: "inherit",
            fontSize: 25,
            width: 40,
            height: 40,
            position: "absolute",
            top: "10px",
            right: "20px",
            borderRadius: "50%",
            color: "inherit",
            [theme.breakpoints.down("sm")]: {
              right: "5px"
            }
          }}
        >
          <Icon icon="formkit:close" />
        </Button>
      </DialogTitle>
      <DialogContent
        sx={{
          [theme.breakpoints.down("sm")]: {
            padding: 0,
          },
        }}
      >
        {children}
      </DialogContent>
    </Dialog>
  );
};
export default Modal;
