import { red } from '@mui/material/colors';
import { createTheme } from '@mui/material/styles';

// A custom theme for this app
const theme = createTheme({
  palette: {
    primary: {
      main: '#2287e7',
    },
    secondary: {
      main: '#00a9ee',
    },
    error: {
      main: red.A400,
    },
  },
  typography: {
    allVariants: {
      fontFamily: "Public Sans, sans-serif",
      textTransform: 'none',
      fontSize: 16,
    },
    button: {
      textTransform: "none",
    }
  },


});

export default theme;