import React from "react";
import { Box, Typography } from "@mui/material";
import { Icon } from "@iconify/react";

const Card = ({ count, title, icon }) => {
  return (
    <Box
      sx={{
        background: "#fff",
        p: "20px 20px",
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        borderRadius: 1,
        boxShadow: 3,
      }}
    >
      <Box>
        <Typography
          variant="h1"
          gutterBottom
          sx={{ fontSize: 18, fontWeight: 500, m: 0 }}
        >
          {title}
        </Typography>

        <Typography
          variant="p"
          gutterBottom
          sx={{ fontSize: 16, fontWeight: 700, m: 0, mt: 1, display: "block" }}
        >
          {count}
        </Typography>
      </Box>
      <Box sx={{ fontSize: 30, color: "primary.main" }}>
        <Icon icon={icon} />
      </Box>
    </Box>
  );
};

export default Card;
