import React from "react";
import { Box, CircularProgress } from "@mui/material";
import Logo from "./logo";

const Preloader = () => {
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        width: "100%",
        height: "100%",
        position: "fixed",
        top: 0,
        left: 0,
        "& img": {
          width: 40,
        },
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
          gap: "20px",
        }}
      >
        <Logo />
        <CircularProgress />
      </Box>
    </Box>
  );
};

export default Preloader;
