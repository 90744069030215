import React from "react";
import { Box, useTheme } from "@mui/material";

const FullLayout = ({ children, image, layoutsize }) => {
  const theme = useTheme();
  return (
    <Box
      sx={{
        display: "flex",
        width: "100%",
        justifyContent: "space-between",
        minHeight: "100vh",
        [theme.breakpoints.down("sm")]: {
          display: "inline-block",
          width: "100%",
          minHeight: 'inherit'
        },
      }}
    >
      <Box
        sx={{
          width: layoutsize ? "35%" : "65%",
          backgroundColor: "#f5f5f5",
          backgroundImage: `url(${image})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
          [theme.breakpoints.down("sm")]: {
            width: "100%",
            height: 150,
          },
        }}
      ></Box>
      <Box
        sx={{
          width: layoutsize ? "65%" : "35%",
          boxShadow: 3,
          [theme.breakpoints.down("sm")]: {
            width: "100%",
            boxShadow: "inherit"
          },
        }}
      >
        {children}
      </Box>
    </Box>
  );
};

export default FullLayout;
