export const formatDate = (date) => {
  var tempDate = date !== undefined && date !== "" ? date : new Date();
  var day, month, year;
  year = tempDate.getFullYear();
  month = tempDate.getMonth() + 1;
  day = tempDate.getDate();
  if (month < 10) {
    month = "0" + month;
  }
  if (day < 10) {
    day = "0" + day;
  }
  return day + "-" + month + "-" + year;
};

export const formatTime = (date) => {
  var tempDate = date !== undefined && date !== "" ? date : new Date();
  var hours = tempDate.getHours();
  var minutes = tempDate.getMinutes();
  var ampm = hours >= 12 ? "PM" : "AM";
  hours = hours % 12;
  hours = hours ? hours : 12; // the hour '0' should be '12'
  minutes = minutes < 10 ? "0" + minutes : minutes;
  var strTime = hours + ":" + minutes + " " + ampm;
  return strTime;
};

export const debounce = (func) => {
  let timer;
  return function (...args) {
    const context = this;
    if (timer) clearTimeout(timer);
    timer = setTimeout(() => {
      timer = null;
      func.apply(context, args);
    }, 500);
  };
};

export function checkProperties(obj) {
  for (var key in obj) {
    if (obj[key] !== null && obj[key] !== "") return false;
  }
  return true;
}

export const userTypes = [
  {
    value: 1,
    name: "Walk-In",
  },
  {
    value: 2,
    name: "Vehicle",
  },
  {
    value: 3,
    name: "Both",
  },
];
export const getLocalData = (key) => {
  return JSON.parse(localStorage.getItem(key));
};
export const getLocalString = (key) => {
  return localStorage.getItem(key);
};
export const NavigateTo = (pathname) => {
  return (window.location.href = pathname);
};
export const AddSiNo = (Array) => {
  if (Array !== undefined && Array?.length > 0) {
    return Array?.map((list, index) => ({
      ...list,
      siNo: index + 1,
    }));
  } else {
    return [];
  }
};
