import React, { lazy, Suspense, useContext } from "react";
import { Routes, Route } from "react-router-dom";
import { Preloader } from "../components";
import { AppContext } from "../App";
import Protected from "./Protected";

// variable
const Login = lazy(() => import("../pages/login"));
const Register = lazy(() => import("../pages/register"));
const NotFound = lazy(() => import("../pages/404"));
const Dashboard = lazy(() => import("../pages/dashboard"));
const LiveReport = lazy(() => import("../pages/liveReport"));
const ConsolidatedReport = lazy(() => import("../pages/consolidatedReport"));
const DashboardRegister = lazy(() => import("../pages/dashboardRegister"));
const VisitorType = lazy(() => import("../pages/VisitorType"));
const Location = lazy(() => import("../pages/Location"));
const PurposeOfVisit = lazy(() => import("../pages/purposeOfVisit"));
const Client = lazy(() => import("../pages/client"));
const Building = lazy(() => import("../pages/building"));
const Gate = lazy(() => import("../pages/Gate"));
const Tenant = lazy(() => import("../pages/tenant"));
const System = lazy(() => import("../pages/system"));
const Host = lazy(() => import("../pages/host"));
const VehicleLiveReport = lazy(() => import("../pages/VehicleSkipReport"));
const WalkinRegister = lazy(() => import("../pages/WalkinReport"));
const WalkinReport = lazy(() => import("../pages/walkinTable"));
const MismatchReport = lazy(() => import("../pages/MismatchReport"));
const WalkinConsokidatedReport = lazy(() =>
  import("../pages/walkinConsolidatedReport")
);
const WalkinQrRegister = lazy(() => import("../pages/WalkinQrReport"));
const VehicleQrReport = lazy(() => import("../pages/VehicleQrReport"));

const Router = () => {
  const { isLoggedIn } = useContext(AppContext);

  function LoggedInAuth({ children }) {
    if (isLoggedIn) {
      window.location.href = "/dashboard";
    }
    return children;
  }

  return (
    <Suspense fallback={<Preloader />}>
      <Routes>
        <Route
          path="/"
          exact
          element={
            <LoggedInAuth>
              <Login />
            </LoggedInAuth>
          }
        />
        <Route
          path="/register"
          element={
            <LoggedInAuth>
              <Register />
            </LoggedInAuth>
          }
        />
        <Route
          path="/dashboard"
          element={
            <Protected isLoggedIn={isLoggedIn}>
              <Dashboard />
            </Protected>
          }
        />
        <Route
          path="/vehicle-live-report"
          element={
            <Protected isLoggedIn={isLoggedIn}>
              <LiveReport />
            </Protected>
          }
        />
        <Route
          path="/vehicle-consolidated-report"
          element={
            <Protected isLoggedIn={isLoggedIn}>
              <ConsolidatedReport />
            </Protected>
          }
        />
        {/* <Route
          path="/dashboard-register"
          element={
            <Protected isLoggedIn={isLoggedIn}>
              <DashboardRegister />
            </Protected>
          }
        /> */}
        <Route
          path="/visitor-purpose"
          element={
            <Protected isLoggedIn={isLoggedIn}>
              <PurposeOfVisit />
            </Protected>
          }
        />
        <Route
          path="/visitor-type"
          element={
            <Protected isLoggedIn={isLoggedIn}>
              <VisitorType />
            </Protected>
          }
        />
        <Route
          path="/location"
          element={
            <Protected isLoggedIn={isLoggedIn}>
              <Location />
            </Protected>
          }
        />
        <Route
          path="/client"
          element={
            <Protected isLoggedIn={isLoggedIn}>
              <Client />
            </Protected>
          }
        />
        <Route
          path="/building"
          element={
            <Protected isLoggedIn={isLoggedIn}>
              <Building />
            </Protected>
          }
        />
        <Route
          path="/tenant"
          element={
            <Protected isLoggedIn={isLoggedIn}>
              <Tenant />
            </Protected>
          }
        />
        <Route
          path="/client"
          element={
            <Protected isLoggedIn={isLoggedIn}>
              <LiveReport />
            </Protected>
          }
        />
        <Route
          path="/gate"
          element={
            <Protected isLoggedIn={isLoggedIn}>
              <Gate />
            </Protected>
          }
        />
        <Route
          path="/system"
          element={
            <Protected isLoggedIn={isLoggedIn}>
              <System />
            </Protected>
          }
        />
        <Route
          path="/host"
          element={
            <Protected isLoggedIn={isLoggedIn}>
              <Host />
            </Protected>
          }
        />
        <Route
          path="/approval"
          element={
            <Protected isLoggedIn={isLoggedIn}>
              <LiveReport />
            </Protected>
          }
        />
        <Route
          path="/vehicle-register"
          element={
            <Protected isLoggedIn={isLoggedIn}>
              <DashboardRegister />
            </Protected>
          }
        />
        <Route
          path="/vehicle-skip-report"
          element={
            <Protected isLoggedIn={isLoggedIn}>
              <VehicleLiveReport />
            </Protected>
          }
        />
        <Route
          path="/walkin-resgister"
          element={
            <Protected isLoggedIn={isLoggedIn}>
              <WalkinRegister />
            </Protected>
          }
        />
        <Route path="/walkin-Resgister-QR" element={<WalkinQrRegister />} />
        <Route path="/vehicle-Resgister-QR" element={<VehicleQrReport />} />
        <Route
          path="/walkin-report"
          element={
            <Protected isLoggedIn={isLoggedIn}>
              <WalkinReport />
            </Protected>
          }
        />
        <Route
          path="/mismatch-report"
          element={
            <Protected isLoggedIn={isLoggedIn}>
              <MismatchReport />
            </Protected>
          }
        />
        <Route
          path="/walkin-consolidated-report"
          element={
            <Protected isLoggedIn={isLoggedIn}>
              <WalkinConsokidatedReport />
            </Protected>
          }
        />

        <Route path="*" exact element={<NotFound />} />
      </Routes>
    </Suspense>
  );
};

export default Router;
