import React, { useState, useRef } from "react";
import {
  Box,
  Avatar,
  Button,
  Menu,
  MenuItem,
  ListItemIcon,
  Typography,
  useTheme,
} from "@mui/material";
import { Icon } from "@iconify/react";

const MENU_OPTIONS = [
  {
    label: "My Profile",
    icon: "ri:profile-line",
    linkTo: "/",
  },
  {
    label: "Settings",
    icon: "material-symbols:settings-outline",
    linkTo: "/",
  },
  {
    label: "Logout",
    icon: "ic:outline-logout",
    linkTo: "/",
  },
];

const AccountsDropdown = () => {
  const theme = useTheme();
  const loginData = JSON.parse(localStorage.getItem("loginData"));
  const anchorRef = useRef(null);
  const [open, setOpen] = useState(false);

  const handleClick = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const HandleMenuItem = (item) => {
    localStorage.removeItem("isLoggedIn", false);
    localStorage.removeItem("logInType", "");
    localStorage.removeItem("loginData");
    if (item?.label === "Logout") {
      window.location.href = "/";
    }
  };

  return (
    <Box sx={{ position: "relative" }}>
      <Button
        sx={{
          padding: 0,
          minWidth: "inherit",
          color: "inherit",
          "&:hover": {
            background: "none",
          },
        }}
        disableFocusRipple
        disableElevation
        disableRipple
        onClick={handleClick}
        ref={anchorRef}
      >
        <Avatar
          alt="Remy Sharp"
          sx={{ width: 40, height: 40, marginRight: "10px" }}
          src="images/avatar1.jpg"
        />
        <Typography
          variant="subtitle1"
          noWrap
          sx={{
            fontWeight: 400,
            color: "#666",
            [theme.breakpoints.down("sm")]: {
              display: "none",
            },
          }}
        >
          {loginData !== null && loginData !== undefined && loginData?.userName}
        </Typography>
      </Button>

      <Menu
        id="account-menu"
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        anchorEl={anchorRef.current}
        sx={{
          "& .MuiPaper-root": {
            width: 200,
            mt: 1.1,
          },
        }}
      >
        {MENU_OPTIONS?.map((list, i) => {
          return (
            <MenuItem
              onClick={() => HandleMenuItem(list)}
              key={i}
              sx={{ pt: 1.5, px: 2, color: "#666" }}
            >
              <ListItemIcon sx={{ fontSize: 22 }}>
                <Icon icon={list.icon} />
              </ListItemIcon>
              {list.label}
            </MenuItem>
          );
        })}
      </Menu>
    </Box>
  );
};

export default AccountsDropdown;
