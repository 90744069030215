import React from "react";
import { Navigate } from "react-router-dom";
function Protected({ isLoggedIn, children }) {
  if (isLoggedIn === null && isLoggedIn === undefined) {
    return <Navigate to="/" replace />;
  } else {
    if (!isLoggedIn) {
      return <Navigate to="/" replace />;
    } else {
      console.log("ROUTER", isLoggedIn);
    }
  }
  return children;
}
export default Protected;
