import React from "react";

const Label = (props) => {
  const { fors, title } = props;
  return (
    <label
      style={{ marginBottom: "10px", display: "inline-block", width: "100%", color: "#666" }}
      for={fors}
    >
      {title}
    </label>
  );
};

export default Label;
