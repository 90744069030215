import React from "react";
import { Box, Stack, useTheme, Typography } from "@mui/material";
import AccountsDropDown from "./accountsDropdown";

const Header = ({ title, button }) => {
  const theme = useTheme();
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        width: "100%",
        borderRadius: "0px",
        marginBottom: "35px",
        [theme.breakpoints.down("sm")]: {
          marginTop: "25px",
        },
      }}
    >
      <Typography
        variant="h2"
        sx={{
          fontWeight: 700,
          fontSize: 22,
          m: 0,
          [theme.breakpoints.down("sm")]: {
            fontSize: 20,
          },
        }}
      >
        {title}
      </Typography>

      <Stack spacing={3} direction="row" sx={{ alignItems: "center" }}>
        {button}
        <Box
          sx={{
            [theme.breakpoints.down("sm")]: {
              display: "none",
            },
          }}
        >
          <AccountsDropDown />
        </Box>
      </Stack>
    </Box>
  );
};

export default Header;
