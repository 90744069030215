import React from "react";
import { Box, Typography } from "@mui/material";

const Heading = ({ children, title }) => {
  return (
    <Box
      sx={{
        mb: 3,
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
      }}
    >
      <Typography
        variant="h2"
        noWrap
        sx={{ fontWeight: 700, fontSize: 25, m: 0 }}
      >
        {title}
      </Typography>
      <Box>{children}</Box>
    </Box>
  );
};

export default Heading;
