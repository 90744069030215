import React from "react";
import { Box, useTheme } from "@mui/material";
import Logo from "./logo";
import Navigation from "./navigation";
import PerfectScrollbar from "react-perfect-scrollbar";

const Sidebar = ({ menuShow }) => {
  const theme = useTheme();
  return (
    <Box
      sx={{
        width: "250px",
        position: "fixed",
        top: 0,
        left: 0,
        zIndex: 2,
        padding: "0 0 20px",
        boxShadow: 3,
        background: "#0b1a26",
        height: "100%",
        overflowY: "auto",
        transition: "0.5s all",
        [theme.breakpoints.down("sm")]: {
          width: "100%",
          zIndex: 8,
          left: menuShow ? "0px" : "-9999px",
          visibility: menuShow ? "visible" : "hidden",
          opacity: menuShow ? 1 : 0,
          height: "calc(100vh - 55px)",
          top: "55px",
        },
      }}
    >
      <PerfectScrollbar>
        <Box
          sx={{
            textAlign: "center",
            padding: "5px 0",
            background: "#fff",
            marginBottom: "15px",
            [theme.breakpoints.down("sm")]: {
              display: "none",
            },
          }}
        >
          <Logo />
        </Box>
        <Navigation />
      </PerfectScrollbar>
    </Box>
  );
};

export default Sidebar;
