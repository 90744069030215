import React from "react";
import { Box, Stack, useTheme, IconButton } from "@mui/material";
import Logo from "./logo";
import AccountsDropDown from "./accountsDropdown";
import { Icon } from "@iconify/react";

const MobileHeader = ({ toggleMenu, toggleState }) => {
  const theme = useTheme();
  return (
    <Box
      sx={{
        display: "none",
        width: "100%",
        padding: "5px 15px",
        height: 55,
        background: "#fff",
        position: "fixed",
        left: 0,
        top: 0,
        zIndex: 2,
        boxShadow: 3,
        [theme.breakpoints.down("sm")]: {
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        },
      }}
    >
      <Box>
        <Logo />
      </Box>
      <Stack spacing={3} direction="row">
        <IconButton
          sx={{
            padding: 0,
            fontSize: "30px",
          }}
          onClick={toggleMenu}
        >
          <Icon icon={toggleState ? "material-symbols:close" : "material-symbols:menu"}  />
        </IconButton>
        <AccountsDropDown />
      </Stack>
    </Box>
  );
};

export default MobileHeader;
